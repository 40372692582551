import { NgModule } from '@angular/core';
import { NgxAdDfpComponent } from './ngx-ad-dfp.component';

@NgModule({
  declarations: [NgxAdDfpComponent],
  imports: [
  ],
  exports: [NgxAdDfpComponent]
})
export class NgxAdDfpModule { }
